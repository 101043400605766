/* Manfred Oeding 2024*/
/* Hauptmenu in Startseite ist versteckt bis man Scrollt */

window.onscroll = function() {scrollFunction()};
function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById("navigation__container").style.display = "flex";
    } else {
        document.getElementById("navigation__container").style.display = "none";
    }
}
